import React from 'react'
import { AuthRole, FirebaseAuthProvider } from '@flock/utils'
import { CssBaseline, ThemeProvider } from '@mui/material'
import { overmoonTheme, OvermoonThemeProvider } from '@flock/shared-ui'
import { SnackbarProvider } from '@flock/flock-component-library'

import ConfiguredApolloProvider from './apollo'
import {
  ENABLE_SIMULATOR,
  SHARE_PROPERTY_QUESTIONNAIRE_URL,
  SHARE_PROPERTY_QUESTIONNAIRE_V3_URL,
  SHARE_PERSONAL_INFO_V3_URL,
  SHARE_MORTGAGE_V3_URL,
  ENABLE_MAINTENANCE_SCREEN,
} from '../constants'
import LoadingCardPage from '../components/SharedComponents/LoadingCardPage'
import MaintenancePage from '../components/SharedComponents/MaintenancePage'

LoadingCardPage.defaultProps = {
  hideLoader: false,
}

LoadingCardPage.defaultProps = {
  hideLoader: false,
}

// eslint-disable-next-line import/prefer-default-export
export const wrapPageElement = ({ element }: any) => {
  let authorizedRoles = [AuthRole.INVESTOR]
  if (ENABLE_SIMULATOR) {
    authorizedRoles = [AuthRole.ADMIN]
  }

  if (ENABLE_MAINTENANCE_SCREEN) {
    return (
      <ThemeProvider theme={overmoonTheme}>
        <OvermoonThemeProvider>
          <MaintenancePage />
        </OvermoonThemeProvider>
      </ThemeProvider>
    )
  }

  if (
    typeof window !== 'undefined' &&
    (window.location.href.includes(SHARE_PROPERTY_QUESTIONNAIRE_URL) ||
      window.location.href.includes(SHARE_PROPERTY_QUESTIONNAIRE_V3_URL) ||
      window.location.href.includes(SHARE_PERSONAL_INFO_V3_URL) ||
      window.location.href.includes(SHARE_MORTGAGE_V3_URL))
  ) {
    return (
      <ThemeProvider theme={overmoonTheme}>
        <OvermoonThemeProvider>
          <ConfiguredApolloProvider>{element}</ConfiguredApolloProvider>
        </OvermoonThemeProvider>
      </ThemeProvider>
    )
  }

  return (
    <ThemeProvider theme={overmoonTheme}>
      <OvermoonThemeProvider>
        <CssBaseline />
        <FirebaseAuthProvider authorizedRoles={authorizedRoles}>
          <ConfiguredApolloProvider>
            <SnackbarProvider>{element}</SnackbarProvider>
          </ConfiguredApolloProvider>
        </FirebaseAuthProvider>
      </OvermoonThemeProvider>
    </ThemeProvider>
  )
}
